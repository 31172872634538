import React, { useEffect } from 'react';
import { BioContainer } from '../components/BioContainer';
import { BioHeader } from '../components/BioHeader';
import { BioStyles } from '../components/BioStyles';

export default function Bio() {
  useEffect(() => {
    document.querySelector('body').classList.remove('white');
    document.querySelector('body').classList.add('grey');
  });

  return (
    <BioStyles>
      <BioHeader>The Sculptor</BioHeader>
      <BioContainer>
        <p>Art studies by Gøsta Loven</p>
        <p>Sweden 1972</p>

        <p>Art studies by Grete Nash</p>
        <p>Norway 1973-74</p>

        <p>Alanus Hochschule der Kunste</p>
        <p>Germany 1974-79</p>

        <p>Art practice in foundry</p>
        <p>Tangen, Norway 1980-85</p>

        <p>Studio at Sørum</p>
        <p>Stange 1982-2010</p>

        <p>Studio in Stålaker quarry</p>
        <p>Larvik 1988-2010</p>

        <p>Hochschule der Kunste</p>
        <p>Berlin 1985-86</p>

        <p>Organizer and participant, symposium Norge, stone </p>
        <p>Larvik, with Makoto Fujiwara 1985-2010</p>

        <p>Organizer and participant, Site-specific projects</p>
        <p>
          Hedemarken, with Wenche Kvalstad Eckhoff and Finn-Åge Andersen
          1991-1999
        </p>

        <p>Architectural studies with Are Vesterlid</p>
        <p>Sørum 1998-2010</p>

        <p>Art consultant and curator for the Directorate of Public Roads</p>
        <p>National Tourist Routs, Norway 1995-2010</p>
      </BioContainer>
    </BioStyles>
  );
}
