import styled from 'styled-components';
import { NavbarStyles } from './Navbar';

export const BioContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 10px;
  justify-content: space-between;

  p {
    font-size: 2.4rem;
    line-height: 3.2rem;
    padding-right: 1rem;
    margin-top: 0;
    margin-bottom: 1rem;
    max-width: 529px;

    @media (min-width: 1024px) {
      padding: 0;
    }
  }

  ${NavbarStyles} {
    color: white;

    li {
      color: var(--white);
    }

    a {
      color: var(--white);
    }
  }
`;
